import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { BaseSerializer } from './base.serializer';
import { Page } from '../shared/models/page';
import { Sort } from '../shared/models/sort';
import { Predicate } from '../shared/enums/predicate.enum';
import { Group } from '../filter/group';
import { BaseList } from './base.list';
import { Column } from '../shared/models/column';
import { CustomHttpParamEncoder } from './custom-http-params-encoder';

@Injectable()
export class BaseService<T, L extends BaseList<T>> {
  serializer: BaseSerializer<T>;
  endpoint: string;

  constructor(public http: HttpClient) {
  }

  public static validationErrorAsSentence(error): string {
    return Object.keys(error).map(key => error[key].map(reason => `${key} ${reason}`)).join(', ');
  }

  fetchAll(fields: string = '', group: Group[] = [], sort: Sort[] = []): Observable<L> {
    const q = JSON.stringify(this.toRansackPage(group, sort));

    let params = new HttpParams({encoder: new CustomHttpParamEncoder()})
      .set('q', q)
      .set('per', '99999');

    if (fields) {
      params = params.set('fields', fields);
    }

    return this.http.get<L>(`${environment.base_url + this.endpoint}`, {params})
      .pipe(
        filter(x => !!x),
        take(1),
        map(res => this.deSerializeResponse(res))
      );
  }

  count(group: Group[] = []): Observable<number> {
    const q = JSON.stringify(this.toRansackMap(group));

    const params = new HttpParams({encoder: new CustomHttpParamEncoder()})
      .set('fields', 'count')
      .set('q', q);

    return this.http
      .get<L>(`${environment.base_url + this.endpoint}`, {params})
      .pipe(
        filter(x => !!x),
        map(res => res.count),
        take(1)
      );
  }

  fetchPage(page: Page | null, group: Group[], sort: Sort[], fields: string): Observable<L> {
    const q = JSON.stringify(this.toRansackPage(group, sort));

    let params = new HttpParams({encoder: new CustomHttpParamEncoder()})
      .set('fields', fields)
      .set('q', q)
      .set('per', '999999');

    if (page) {
      params = params
        .set('page', (page.offset + 1).toString())
        .set('per', page.size.toString());
    }

    return this.http.get<L>(`${environment.base_url + this.endpoint}`, {params}).pipe(
      filter(x => !!x),
      map(res => this.deSerializeResponse(res))
    );
  }

  fetchList(ids: number[], fields: string): Observable<L> {
    const q = JSON.stringify(this.listToRansack(ids, []));

    const params = new HttpParams({encoder: new CustomHttpParamEncoder()})
      .set('per', '999999')
      .set('fields', fields)
      .set('q', q);

    return this.http
      .get<L>(`${environment.base_url + this.endpoint}`, {params})
      .pipe(
        filter(x => !!x),
        take(1),
        map(res => this.deSerializeResponse(res))
      );
  }

  fetch(id: number): Observable<T> {
    return this.http.get(environment.base_url + this.endpoint + '/' + id).pipe(
      filter(x => !!x),
      take(1),
      map(res => this.serializer.fromJson(res))
    );
  }

  create(obj: T | Partial<T>): Observable<T> {
    return this.http.post(environment.base_url + this.endpoint, this.serializer.toJson(obj)).pipe(
      filter(x => !!x),
      take(1),
      map(res => this.serializer.fromJson(res))
    );
  }

  update(id: number, obj: Partial<T>): Observable<T> {
    return this.http.put(`${environment.base_url + this.endpoint}/${id}`, this.serializer.toJson(obj as any)).pipe(
      filter(x => !!x),
      take(1),
      map(res => this.serializer.fromJson(res))
    );
  }

  delete(id: number): Observable<T> {
    return this.http.delete<T>(`${environment.base_url + this.endpoint}/${id}`).pipe(
      take(1)
    );
  }

  toRansackPage(group: Group[], sort: Sort[]) {
    const q = {s: sort, g: {}};
    for (let i = 0; i < group.length; i++) {
      const g = group[i];
      q.g[i.toString()] = {m: 'or', c: {}};
      q.g[i.toString()]['m'] = g.m;
      for (let o = 0; o < g.filter.length; o++) {
        const f = g.filter[o];
        q.g[i.toString()]['c'][o.toString()] = {
          a: {0: {name: f.subject.replace(/\./g, '_')}},
          p: Column.getPredicateString(f.predicate),
          v: {0: {value: f.object1}}
        };
      }
    }
    return q;
  }

  listToRansack(ids: number[], sort: Sort[]): Object {
    const q = {
      s: sort, g: [
        {
          m: 'and',
          c: []
        }
      ]
    };
    const v = {};
    ids.forEach((id, i) => {
      v[i.toString()] = {value: id};
    });
    q.g[0]['c'][0] = {
      a: {0: {name: 'id'}},
      p: 'in',
      v: v
    };
    return q;
  }

  toRansackMap(group: Group[]) {
    const q = {g: {}};
    for (let i = 0; i < group.length; i++) {
      const g = group[i];
      q.g[i.toString()] = {m: 'or', c: {}};
      q.g[i.toString()]['m'] = g.m;
      for (let o = 0; o < g.filter.length; o++) {
        const f = g.filter[o];
        q.g[i.toString()]['c'][o.toString()] = {
          a: {0: {name: f.subject.replace(/\./g, '_')}},
          p: Column.getPredicateString(f.predicate),
          v: {0: {value: f.object1}}
        };
      }
    }
    return q;
  }

  /** builds a "q" for ransack to only load entity where prop is value
   *
   * @param prop - property to filter
   * @param value - what value should the prop have
   * @param pred - which predicate to use
   * @returns Group
   */
  simpleQ(prop: string, value: any, pred = Predicate.EQUAL): Group[] {
    return [{
      m: 'and',
      filter: [
        {
          subject: prop,
          predicate: pred,
          object1: value.toString()
        }
      ]
    }];
  }

  private deSerializeResponse(res) {
    if (!res.data) {
      res.data = [];
    }

    res.data = res.data.map(o => {
      const deserialized = this.serializer.fromJson(o);
      return this.expandFlattenedKeys(deserialized);
    });
    return res;
  }

  /*
    When the requested fields include keys like 'business.name',
    the response will be `{ "business.name": "value" }`.
    this method will expand this keys to objects.
    For backwards compatibility, the original key will be kept,
    so the response will be `{ "business.name": "value", business: { name: "value" } }`.
  */
  private expandFlattenedKeys(object) {
    const expandedObject = {};
    for (const [key, value] of Object.entries(object)) {
      key.split('.').reduce((acc, part, i, arr) => {
        if (i === arr.length - 1) {
          acc[part] = value;
        } else {
          acc[part] = acc[part] || {};
        }
        return acc[part];
      }, object);
    }
    return object;
  }
}

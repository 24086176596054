import {Column} from '../shared/models/column';
import {ColumnType} from '../shared/enums/column-type.enum';
import { Invoice } from '../invoices/invoice';
import { AddonSubscription } from '../addon_subscriptions/addon_subscription';

export class LineItem {
  // allColumns for DataTable
  static allColumns: Column[] = [
    new Column({name: '#', prop: 'id', type: ColumnType.Number, standard: true}),
    new Column({name: 'Name', prop: 'name', type: ColumnType.String, standard: true}),
    new Column({name: 'Beschreibung', prop: 'description', type: ColumnType.String, standard: true}),
    new Column({name: 'quantity', prop: 'quantity', type: ColumnType.Number, standard: true}),
    new Column({name: 'price_cents', prop: 'price_cents', type: ColumnType.Number, standard: true}),
    new Column({name: 'Gelöscht am', prop: 'deleted_at', type: ColumnType.DateTime}),
    new Column({name: 'Erstellt am', prop: 'created_at', type: ColumnType.DateTime}),
    new Column({name: 'Aktualisiert am', prop: 'updated_at', type: ColumnType.DateTime}),
    new Column({name: 'position', prop: 'position', type: ColumnType.Number}),
    // Associated Columns
    // new Column({name: 'Rechnung', prop: 'invoice', association: 'belongs_to', columns: Invoice.allColumns}),
  ];

  // Simple Columns
  id: number;
  invoice_id: number;
  addon_subscription_id: number;
  business_id: number;
  name: string;
  description: string;
  quantity: number;
  price_cents: number;

  discount_percent: number;
  discount_description: string;

  deleted_at: boolean;
  created_at: boolean;
  updated_at: boolean;
  position: number;

  recurring_rule: 'singular' | 'monthly' | 'yearly';
  recurring_interval: number;
  recurring: boolean;
  previous_recurrings: number;
  max_recurrings: number;
  starts_at: string;
  ends_at: string;
  partial_factor: number;

  // Associations
  invoice: Invoice;
  addon_subscription: AddonSubscription;
  _destroy: boolean;

  constructor(values: Partial<LineItem> = {}) {
    this.previous_recurrings = 1;
    this.recurring = false;
    this.partial_factor = 1;
    Object.assign(this, values);

    if (values.addon_subscription) {
      this.addon_subscription = new AddonSubscription(values.addon_subscription);
    }
  }

  get starts_at_date(): Date {
    return this.starts_at ? new Date(Date.parse(this.starts_at)) : null;
  }

  get ends_at_date(): Date {
    return this.ends_at ? new Date(Date.parse(this.ends_at)) : null;
  }

  get price(): number {
    return this.price_cents / 100;
  }

  set price(p: number) {
    this.price_cents = p * 100;
  }

  get sum(): number {
    const sum = this.quantity * this.price * this.partial_factor;
    return isNaN(sum) ? 0 : sum;
  }

  get discounted_sum(): number {
    return this.sum * (100.0 - this.discount_percent) / 100;
  }

  get fullSum(): number {
    const sum = this.quantity * this.price;
    return isNaN(sum) ? 0 : sum;
  }

  humanize() {
    return this.invoice_id;
  }

  hasMissingStartsAt() {
    return this.recurring_rule === 'yearly' && !this.starts_at;
  }

  recalculatePartialFactor(invoice: Invoice) {
    if (this.starts_at || this.ends_at) {

      let s: any = this.starts_at ? new Date(this.starts_at) : new Date(2000, 0, 0);
      const invoiceStart: any = new Date(invoice.period_start);
      let e: any = this.ends_at ? new Date(this.ends_at) : new Date(2100, 0, 0);
      const invoiceEnd: any = new Date(invoice.period_end);

      s = s > invoiceStart ? s : invoiceStart;
      e = e < invoiceEnd ? e : invoiceEnd;

      const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

      let diffDays = Math.ceil((e.getTime() + oneDay - s.getTime()) / (oneDay));
      diffDays = diffDays < 0 ? 0 : diffDays;
      const invoiceDays = Math.ceil(Math.abs((invoiceEnd.getTime() + oneDay - invoiceStart.getTime()) / (oneDay)));
      this.partial_factor = diffDays / invoiceDays;
    } else {
      this.partial_factor = 1;
    }
  }
}

export class LineItemList {
  count: number;
  data: LineItem[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

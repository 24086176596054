import { Column } from '../shared/models/column';
import { ColumnType } from '../shared/enums/column-type.enum';
import { Client } from '../clients/client';
import { ClientDocument } from '../client_documents/client_document';
import { SepaMandate } from '../sepa_mandates/sepa_mandate';
// import { ClientDocument } from '../client_documents/client_document';
// import { SepaMandate } from '../sepa_mandates/sepa_mandate';

export class Contract {
  static starter_packages = {
    '500_cards': 'Starter-Set: 500 Plastikkarten mit deinem Logo und Design (einmalig 99€)',
    '1000_cards': 'Starter-Set: 1.000 Plastikkarten mit deinem Logo und Design (einmalig 229€)',
    '2000_cards': 'Starter-Set: 2.000 Plastikkarten mit deinem Logo und Design (einmalig 479€)',
    'no_cards': 'Keine Kundenkarten'
  };
  static starter_package_fees = {
    'Starter-Set: 500 Plastikkarten mit deinem Logo und Design (einmalig 99€)': 9900,
    'Starter-Set: 1.000 Plastikkarten mit deinem Logo und Design (einmalig 229€)': 22900,
    'Starter-Set: 2.000 Plastikkarten mit deinem Logo und Design (einmalig 479€)': 47900,
    'Keine Kundenkarten': 0
  };
  static starter_package_options = Object.values(Contract.starter_packages);
  static default_starter_package = null;

  static setup_fee_options = {
    standard: '149€ pro Filiale',
    saver: '99€ pro Filiale',
    cheap: '49€ pro Filiale',
    standardFlex: 'Einrichtungsgebühr flex',
    free: 'keine Einrichtungsgebühr'
  };
  static default_setup_fee = Contract.setup_fee_options.free;
  static contract_terms = {
    oneyear: '12 Monate',
    twoyears: '24 Monate (5% Rabatt/Monat)',
    threeyears: '36 Monate (10% Rabatt/Monat)',
    fouryears: '48 Monate (12% Rabatt/Monat)',
    twoyearsEUR: '24 Monate (5€ Rabatt/Monat)',
    threeyearsEUR: '36 Monate (10€ Rabatt/Monat)',
    fouryearsEUR: '48 Monate (12€ Rabatt/Monat)',
    onemonth: 'Monatlich kündbar (+20%/Monat)'
  };
  static default_contract_term = Contract.contract_terms.oneyear;
  static trial_periods = {
    none: 'keine Testphase',
    free: '1 Monat kostenlos & unverbindlich',
    onemonth: '1 Monat (49€)',
    flex: '1 Monat flex'
  };
  static default_trial_period = Contract.trial_periods.none;
  static own_hardware_options = {
    none: 'keine',
    terminal: 'Eigenes iPad (20€ Rabatt/Monat)',
    stand: 'Eigener Ständer (5€ Rabatt/Monat)',
    terminalandstand: 'Eigenes iPad + Ständer (25€ Rabatt/Monat)'
  };
  static default_own_hardware = Contract.own_hardware_options.none;
  static payment_intervals = {
    // onemonth: 'monatlich per SEPA-Lastschrift',
    // oneyear: '12 Monate im Voraus (4% Rabatt) per SEPA-Lastschrift',
    // twoyears: '24 Monate im Voraus (8% Rabatt) per SEPA-Lastschrift',
    // threeyears: '36 Monate im Voraus (12% Rabatt) per SEPA-Lastschrift',
    total_amount_in_advanced: 'Gesamtbetrag im Voraus',
    monthly: 'Monatliche Zahlung',
    monthly_plus_10eur: 'Monatliche Zahlung (zzgl. 10€/Monat)'
  };
  static default_payment_interval = Contract.payment_intervals.monthly_plus_10eur;
  static terminal_colors = {
    standard: 'Standard (weißes Terminal auf Aluminium-Ständer',
    black: 'Schwarz (schwarzes Terminal auf schwarz eloxiertem Alu-Ständer)',
  };
  static default_terminal_color = Contract.terminal_colors.standard;
  static terminal_restrictions = {
    standard: 'Kiosk-Modus (Nur Mankido POS App)',
    safariappstore: 'Safari und App Store freigeschaltet',
  };
  static default_terminal_restrictions = Contract.terminal_restrictions.standard;
  static terminal_costs = {
    less: 49,
    standard: 59,
    more: 69
  };
  static default_terminal_costs = Contract.terminal_costs.standard;
  static country_codes = {
    'DE': 'Deutschland',
    'AT': 'Österreich',
    'IT': 'Italien',
    'CH': 'Schweiz',
    'NL': 'Niederlande',
    'LU': 'Luxemburg',
    'MT': 'Malta',
    'CY': 'Zypern',
    'FR': 'Frankreich',
    'PL': 'Polen'
  };
  // allColumns for DataTable
  static allColumns: Column[] = [
    new Column({name: '#', prop: 'id', type: ColumnType.Number, standard: true, order: 0, flexGrow: .3}),
    new Column({
      name: 'PaperlessSubmissionId',
      prop: 'paperless_submission_id',
      type: ColumnType.Number,
      standard: false,
      order: 0,
      flexGrow: .8
    }),
    new Column({name: 'Status', prop: 'state', type: ColumnType.Number}),
    new Column({name: 'Firma', prop: 'company', type: ColumnType.String, standard: true, order: 1}),
    new Column({name: 'Kontakt', prop: 'contact', type: ColumnType.String, standard: true, order: 2}),
    new Column({name: 'Telefon', prop: 'phone', type: ColumnType.String}),
    new Column({name: 'mobile', prop: 'mobile', type: ColumnType.String}),
    new Column({name: 'E-Mail', prop: 'email', type: ColumnType.String}),
    new Column({name: 'Adresse', prop: 'address', type: ColumnType.String}),
    new Column({name: 'PLZ', prop: 'zip', type: ColumnType.String}),
    new Column({name: 'Stadt', prop: 'city', type: ColumnType.String}),
    new Column({name: 'Länder Code', prop: 'country_code', type: ColumnType.String}),
    new Column({name: 'Kontoinhaber', prop: 'bankaccount_owner', type: ColumnType.String}),
    new Column({name: 'IBAN', prop: 'iban', type: ColumnType.String}),
    new Column({name: 'bic', prop: 'bic', type: ColumnType.String}),
    new Column({name: 'Paket', prop: 'package', type: ColumnType.String, standard: true, order: 3}),
    new Column({name: 'Startpaket', prop: 'starter_package', type: ColumnType.String}),
    new Column({name: 'setup_fee', prop: 'setup_fee', type: ColumnType.String}),
    new Column({name: 'service_fee', prop: 'service_fee', type: ColumnType.String}),
    new Column({name: 'trial_period', prop: 'trial_period', type: ColumnType.String, standard: true, order: 4}),
    new Column({name: 'contract_term', prop: 'contract_term', type: ColumnType.String, standard: true, order: 5}),
    new Column({name: 'additional_terminals', prop: 'additional_terminals', type: ColumnType.String}),
    new Column({name: 'own_hardware', prop: 'own_hardware', type: ColumnType.String}),
    new Column({name: 'special_agreements', prop: 'special_agreements', type: ColumnType.String, standard: true, order: 6}),
    new Column({name: 'payment_interval', prop: 'payment_interval', type: ColumnType.String}),
    new Column({name: 'mandate_signee', prop: 'mandate_signee', type: ColumnType.String}),
    new Column({name: 'mandate_date_of_signature', prop: 'mandate_date_of_signature', type: ColumnType.DateTime}),
    new Column({name: 'mandate_place_of_signature', prop: 'mandate_place_of_signature', type: ColumnType.String}),
    new Column({name: 'signee', prop: 'signee', type: ColumnType.String}),
    new Column({name: 'date_of_signature', prop: 'date_of_signature', type: ColumnType.Date, standard: true, order: 7, flexGrow: .7}),
    new Column({name: 'place_of_signature', prop: 'place_of_signature', type: ColumnType.String}),
    new Column({name: 'started_at', prop: 'started_at', type: ColumnType.DateTime}),
    new Column({name: 'expires_at', prop: 'expires_at', type: ColumnType.DateTime}),
    new Column({name: 'Gelöscht am', prop: 'deleted_at', type: ColumnType.DateTime}),
    new Column({name: 'Erstellt am', prop: 'created_at', type: ColumnType.DateTime}),
    new Column({name: 'Aktualisiert am', prop: 'updated_at', type: ColumnType.DateTime}),
    new Column({name: 'discount', prop: 'discount', type: ColumnType.String}),
    new Column({name: 'terminal_cellular', prop: 'terminal_cellular', type: ColumnType.String}),
    new Column({name: 'terminal_color', prop: 'terminal_color', type: ColumnType.String}),
    new Column({name: 'terminal_restrictions', prop: 'terminal_restrictions', type: ColumnType.String}),
    // Associated Columns
    new Column({name: 'Kunde', prop: 'client', association: 'belongs_to', columns: Client.allColumns}),
    new Column({name: 'Dokument', prop: 'client_document', association: 'has_one', columns: ClientDocument.allColumns}),
    // new Column({name: 'SEPA Mandat', prop: 'sepa_mandate', association: 'has_one', columns: SepaMandate.allColumns}),
  ];


  // Simple Columns
  id: number;
  paperless_submission_id: number;
  client_id: number;
  state: number;
  company: string;
  contact: string;
  phone: string;
  mobile: string;
  email: string;
  address: string;
  zip: string;
  city: string;
  country_code: string;
  bankaccount_owner: string;
  iban: string;
  bic: string;
  vat_id: string;
  package: string;
  starter_package: typeof Contract.starter_package_options[number];
  setup_fee: string;
  service_fee: string;
  trial_period: string;
  contract_term: string;
  additional_terminals: string;
  own_hardware: string;
  special_agreements: string;
  payment_interval: string;
  mandate_signee: string;
  mandate_date_of_signature: Date;
  mandate_place_of_signature: string;
  signee: string;
  date_of_signature: Date;
  place_of_signature: string;
  signature: string;
  started_at: Date;
  expires_at: Date;
  deleted_at: Date;
  created_at: Date;
  updated_at: Date;
  discount: string;
  terminal_cellular: string;
  terminal_color: string;
  terminal_restrictions: string;

  remote_sealed_pdf_url: string;

  // Associations
  client: Client;
  // client_document: ClientDocument[];
  client_document: ClientDocument;
  sepa_mandate: SepaMandate;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

  humanize() {
    return `${this.company} - ${this.contact} (${this.package})`;
  }
}

export class ContractList {
  count: number;
  data: Contract[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

import {Column} from '../shared/models/column';
import {ColumnType} from '../shared/enums/column-type.enum';
import { PosMessage } from '../pos_messages/pos_message';
import {Business} from '../businesses/business';
import {Administrator} from '../administrators/administrator';
import {TabletDatum} from '../tablet_data/tablet_datum';
import {Terminal} from '../terminals/terminal';

export class Tablet {
  // allColumns for DataTable
  static allColumns: Column[] = [
    new Column({name: '#', prop: 'id', type: ColumnType.Number}),
    new Column({name: 'Name', prop: 'name', type: ColumnType.String}),
    new Column({name: 'Seriennummer', prop: 'serial_number', type: ColumnType.String, standard: true, order: 0, flexGrow: .5}),
    new Column({name: 'Hersteller', prop: 'manufacturer', type: ColumnType.String}),
    new Column({name: 'Modell', prop: 'model', type: ColumnType.String}),
    new Column({name: 'cellular', prop: 'cellular', type: ColumnType.String, flexGrow: .3}),
    new Column({name: 'capacity', prop: 'capacity', type: ColumnType.String}),
    new Column({name: 'color', prop: 'color', type: ColumnType.String, flexGrow: .3}),
    new Column({name: 'origin', prop: 'origin', type: ColumnType.String}),
    new Column({name: 'device_display_name', prop: 'device_display_name', type: ColumnType.String}),
    new Column({name: 'device_model', prop: 'device_model', type: ColumnType.String}),
    new Column({name: 'model_display_name', prop: 'model_display_name', type: ColumnType.String}),
    new Column({name: 'raw_device_model', prop: 'raw_device_model', type: ColumnType.String}),
    new Column({name: 'Gelöscht am', prop: 'deleted_at', type: ColumnType.DateTime}),
    new Column({name: 'Erstellt am', prop: 'created_at', type: ColumnType.DateTime}),
    new Column({name: 'Aktualisiert am', prop: 'updated_at', type: ColumnType.DateTime}),
    new Column({name: 'app_version', prop: 'app_version', type: ColumnType.String}),
    new Column({name: 'deploy_channel', prop: 'deploy_channel', type: ColumnType.String}),
    new Column({name: 'unlocked_at', prop: 'unlocked_at', type: ColumnType.DateTime}),
    new Column({name: 'Qualität', prop: 'quality', type: ColumnType.String}),
    new Column({name: 'Defekte', prop: 'defects', type: ColumnType.ArrayOfStrings}),
    // Associated Columns
    new Column({name: 'Terminal', prop: 'terminal', association: 'has_one', columns: [
        new Column({name: '#', prop: 'id', type: ColumnType.Number}),
        new Column({name: 'Hersteller', prop: 'manufacturer', type: ColumnType.String}),
        new Column({name: 'Modell', prop: 'model', type: ColumnType.String}),
        new Column({name: 'Seriennummer', prop: 'serial_number', type: ColumnType.String}),
        new Column({name: 'UUID', prop: 'uuid', type: ColumnType.String}),
        // new Column({name: 'Standort ID', prop: 'business_id', type: ColumnType.Number}),
        new Column({name: 'token_issued_at', prop: 'token_issued_at', type: ColumnType.DateTime}),
        new Column({name: 'Gelöscht am', prop: 'deleted_at', type: ColumnType.DateTime}),
        new Column({name: 'Letzter Kontakt', prop: 'last_ping_at', type: ColumnType.DateTime, flexGrow: .5}),
        new Column({name: 'Letzte IP', prop: 'last_ip', type: ColumnType.String}),
        new Column({name: 'Letztes kippen', prop: 'last_tilt', type: ColumnType.String}),
        new Column({name: 'Zuletzt benutzt am', prop: 'last_used_at', type: ColumnType.DateTime, flexGrow: .5}),
        new Column({name: 'Einstellungen', prop: 'settings', type: ColumnType.String}),
        new Column({name: 'Erstellt am', prop: 'created_at', type: ColumnType.DateTime}),
        new Column({name: 'Deaktiviert am', prop: 'deactivated_at', type: ColumnType.DateTime}),
        new Column({name: 'Letzte Authorisierung', prop: 'last_auth', type: ColumnType.Number}),
        new Column({name: 'public_key', prop: 'public_key', type: ColumnType.String}),
        new Column({name: 'Aktiviert am', prop: 'activated_at', type: ColumnType.DateTime}),
        // Associated Columns
        new Column({name: 'Standort', prop: 'business', association: 'belongs_to', columns: Business.allColumns}),
        new Column({name: 'administrator', prop: 'administrator', association: 'belongs_to', columns: Administrator.allColumns,
          standard: true, standardProps: 'name', order: 4}),
        new Column({name: 'created_by', prop: 'created_by', association: 'belongs_to', columns: Administrator.allColumns}),
        new Column({name: 'deactivated_by', prop: 'deactivated_by', association: 'belongs_to', columns: Administrator.allColumns}),
      ], standardProps: 'business.name,business.partner.name,last_ping_at,last_used_at', standard: true, order: 1}),
    // new Column({name: 'tablet_datum', prop: 'tablet_datum', association: 'has_one', columns: TabletDatum.allColumns}),
  ];

  // Simple Columns
  id: number;
  name: string;
  serial_number: string;
  manufacturer: string;
  model: string;
  cellular: string;
  capacity: string;
  color: string;
  origin: string;
  device_display_name: string;
  device_model: string;
  device_id: string;
  model_display_name: string;
  raw_device_model: string;
  apple_id: string;
  apple_id_password: string;
  apple_id_secret1: string;
  apple_id_secret2: string;
  apple_id_secret3: string;
  deleted_at: boolean;
  created_at: boolean;
  updated_at: boolean;
  app_version: string;
  deploy_channel: string;
  unlocked_at: boolean;
  uuid: string;
  quality: string;
  defects: string[] = [];
  error_description: string;
  // Associations
  // terminal: Terminal[];
  terminal: Terminal;
  // tablet_datum: TabletDatum[];
  tablet_datum: TabletDatum;
  pos_message: PosMessage[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

  humanize() {
    return this.name;
  }

  humanized_serial_number() {
    return this.serial_number.match(/.{1,4}/g).join(' ');
  }
}

export class TabletList {
  count: number;
  data: Tablet[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

import { Column } from '../shared/models/column';
import { ColumnType } from '../shared/enums/column-type.enum';
import { Client } from '../clients/client';
import { Business } from '../businesses/business';
import { Administrator } from '../administrators/administrator';

export enum ShipmentTypes {
  delivery = 'Shipments::DeliveryShipment',
  retoure = 'Shipments::ReturnShipment',
}

export enum ShipmentPresets {
  starter = 'starter',
  cards_200 = 'cards_200',
  cards_300 = 'cards_300',
  cards_400 = 'cards_400',
  replacement = 'replacement'
}

export enum ShipmentReceivers {
  returns01 = 'RetourenLager01',
}

export class Shipment {
  // allColumns for DataTable
  static allColumns: Column[] = [
    new Column({name: '#', prop: 'id', type: ColumnType.Number, standard: true, flexGrow: .3}),
    new Column({name: 'type', prop: 'type', type: ColumnType.String, standard: true, flexGrow: 1, options: ShipmentTypes}),
    new Column({name: 'preset', prop: 'preset', type: ColumnType.String, standard: true, flexGrow: 1, options: ShipmentPresets}),
    new Column({name: 'Firma', prop: 'company', type: ColumnType.String, standard: true, flexGrow: 1}),
    new Column({name: 'Name', prop: 'name', type: ColumnType.String, standard: true, flexGrow: 1}),
    new Column({name: 'street', prop: 'street', type: ColumnType.String}),
    new Column({name: 'PLZ', prop: 'zip', type: ColumnType.String}),
    new Column({name: 'Stadt', prop: 'city', type: ColumnType.String}),
    new Column({name: 'country_code', prop: 'country_code', type: ColumnType.String, standard: true, flexGrow: .5}),
    new Column({name: 'weight', prop: 'weight', type: ColumnType.Number}),
    new Column({name: 'content', prop: 'content', type: ColumnType.String, standard: true, flexGrow: 1}),
    new Column({name: 'reference', prop: 'reference', type: ColumnType.String, standard: true, flexGrow: 1}),
    new Column({name: 'parcel_number', prop: 'parcel_number', type: ColumnType.String, standard: true, flexGrow: 1}),
    new Column({name: 'label', prop: 'label', type: ColumnType.String}),
    new Column({name: 'qr_code', prop: 'qr_code', type: ColumnType.String}),
    new Column({name: 'Empfänger', prop: 'receiver_id', type: ColumnType.String, standard: true, flexGrow: 1, options: ShipmentReceivers}),
    new Column({name: 'Email', prop: 'email', type: ColumnType.String}),
    new Column({name: 'Erstellt am', prop: 'created_at', type: ColumnType.DateTime}),
    new Column({name: 'Aktualisiert am', prop: 'updated_at', type: ColumnType.DateTime}),
    new Column({name: 'Gelöscht am', prop: 'deleted_at', type: ColumnType.DateTime}),
    // Associated Columns
    new Column({name: 'Kunde', prop: 'client', association: 'belongs_to', columns: Client.allColumns}),
    new Column({name: 'Standort', prop: 'business', association: 'belongs_to', columns: Business.allColumns}),
    new Column({name: 'administrator', prop: 'administrator', association: 'belongs_to', columns: Administrator.allColumns}),
  ];

  // Simple Columns
  id: number;
  type: ShipmentTypes;
  client_id: number;
  business_id: number;
  administrator_id: number;
  preset: ShipmentPresets;
  company: string;
  name: string;
  street: string;
  zip: string;
  city: string;
  country_code: string;
  weight: number;
  content: string;
  reference: string;
  parcel_number: string;
  label: { url: string, cropped: { url: string } };
  qr_code: string;
  receiver_id: string;
  email: string;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date;
  // Associations
  client: Client;
  business: Business;
  administrator: Administrator;

  constructor(values: Partial<Shipment> = {}) {
    Object.assign(this, values);
  }

  humanize() {
    return `${this.company}`;
  }
}

export class ShipmentList {
  count: number;
  data: Shipment[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

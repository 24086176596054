export enum ColumnType {
  String,
  Number,
  Image,
  Url,
  Date,
  DateTime,
  Boolean,
  Boolean_Inverted,
  Currency,
  MC,
  JSON,
  Polymorphic,
  Custom,
  ArrayOfStrings
}
